import { useMutation } from "@apollo/client"
import { useFormik } from "formik"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import React, { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import styled from "styled-components"
import { CREATE_EMAIL_SUBSCRIPTION } from "../schemas/email-subscription"
import * as yup from "yup"
import CompletedSubscription from "./CompletedSubscription"

export const EmailSubscriptionWrapper = styled.div`
    @media (max-width: 680px) {
        padding: 0px 30px;
    }
    width: 100%;
    height: auto;
    display: flex;
    margin: 50px auto 0;
    padding: 0 100px;
    max-width: 1024px;
    flex-direction: column;
    box-pack: start;
    justify-content: flex-start;
`

export const FormWrapper = styled.div`
    @media (max-width: 680px) {
        flex-direction: column;
    }
    width: 100%;
    height: auto;
    display: flex;
    max-width: 1024px;
    flex-direction: row;
    box-pack: start;
    justify-content: flex-start;
`

const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    locale: yup.string().required()
})

const SubmitButton = ({ className, children, disabled }) => (
    <Button
        className={className}
        size="lg"
        variant="primary"
        disabled={disabled}
        type="submit"
    >
        {children}
    </Button>
)

const StyledSubmitButton = styled(SubmitButton)`
    @media (max-width: 680px) {
        margin: 20px 0 0 0;
    }
    margin: 0 0 0 20px;
`

const EmailSubscription = ({ intl }) => {
    const [registered, setRegistered] = useState(false)
    const [register] = useMutation(CREATE_EMAIL_SUBSCRIPTION, {
        onCompleted: (data) => {
            setRegistered(true)
        }
    })

    const resetSubmitting = (actions) => () => actions.setSubmitting(false)

    const onSubmit = (values, actions) => {
        register({
            variables: {
                input: values
            },
            update: resetSubmitting(actions)
        }).catch(resetSubmitting(actions))
    }

    const {
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        isValid,
        handleSubmit
    } = useFormik({
        initialValues: { email: "", locale: intl.locale },
        onSubmit,
        validationSchema
    })

    return (
        <EmailSubscriptionWrapper>
            <Card bg="dark" border="dark">
                <Card.Body>
                    {!registered ? (
                        <>
                            <p>
                                <FormattedMessage
                                    id="components.email_subscription.prompt_text"
                                    defaultMessage="Want to receive updates and sign-up to the Beta testing waiting list? Then subscribe!"
                                />
                            </p>
                            <Form noValidate onSubmit={handleSubmit}>
                                <FormWrapper>
                                    <Form.Control
                                        id="email"
                                        size="lg"
                                        type="text"
                                        autoCapitalize="none"
                                        value={values.email}
                                        placeholder="Email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={
                                            touched.email && errors.email
                                        }
                                    />
                                    <StyledSubmitButton
                                        disabled={
                                            values.email.length === 0 ||
                                            isSubmitting ||
                                            !isValid
                                        }
                                    >
                                        <FormattedMessage
                                            id="components.email_subscription.subscribe_button"
                                            defaultMessage="Subscribe"
                                        />
                                    </StyledSubmitButton>
                                </FormWrapper>
                            </Form>
                        </>
                    ) : (
                        <CompletedSubscription />
                    )}
                </Card.Body>
            </Card>
        </EmailSubscriptionWrapper>
    )
}

export default injectIntl(EmailSubscription)
