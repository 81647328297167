import React from "react"
import { Badge } from "react-bootstrap"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import styled from "styled-components"

const CompletedSubscriptionWrapper = styled.h4`
    justify-content: center;
    margin: 0;
`

const CompletedSubscription = () => (
    <CompletedSubscriptionWrapper>
        <FormattedMessage
            id="components.email_subscription.completed_text"
            defaultMessage="Thanks for subscribing! Now go hit Verify in your E-mail"
            values={{
                badge: (content) => (
                    <Badge size="lg" variant="primary">
                        {content}
                    </Badge>
                )
            }}
        />
    </CompletedSubscriptionWrapper>
)

export default injectIntl(CompletedSubscription)