import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FormattedMessage, injectIntl, useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout"
import SearchEngineOptimization from "../components/SearchEngineOptimization"
import EmailSubscription from "../components/EmailSubscription"

const IndexPage = () => {
    const intl = useIntl()
    return (
        <Layout pageInfo={{ pageName: "index" }}>
            <SearchEngineOptimization
                lang={intl.locale}
                title={intl.formatMessage({ id: "pages.index.seo.title" })}
                keywords={[
                    `electric`,
                    `vehicle`,
                    `charging`,
                    `bitcoin`,
                    `lightning`
                ]}
            />
            <StaticImage
                alt="Satimoto"
                src="../images/logo-300dpi.png"
                placeholder="none"
                width={400}
            />
            <h1 className="hero">
                <FormattedMessage
                    id="pages.index.hero.title"
                    defaultMessage="Hello Satimoto"
                    values={{
                        gradient: (content) => ( 
                            <span className="text-gradient">{content}</span>
                        )
                    }}
                />
            </h1>
            <p className="hero">
                <FormattedMessage
                    id="pages.index.hero.text"
                    defaultMessage="Recharge your vehicle with Lightning"
                />
            </p>
            <EmailSubscription />
        </Layout>
    )
}

export default injectIntl(IndexPage)
